<template>
  <vx-card class="access-groups-list main-box top-zero-radius md:w-1/2 mx-auto"
           v-if="Object.keys(tree).length">
    <!--<dynamic-table :columns-label="columnsLabel" :data="accessList"/>-->
    <crud-tree :tree="tree"
               :first="true"
               :options="options"
               v-model="selectedRows"/>
  </vx-card>
</template>

<script>
import CrudTree from '@/components/crudTree/crudTree'
import {getRoles} from '@/http/requests/roles'

export default {
  name: 'accessGroupsList',
  metaInfo () {
    return {
      title: this.$t('setting.access.title')
    }
  },
  components: {CrudTree},
  data () {
    return {
      options: {
        type: 'accessList'
      },
      tree: {},
      selectedRows: [],
      columnsLabel: [
        {
          name: 'rowNumber',
          i18n: 'setting.access.table.header.row',
          width: '10%',
          filters: [],
          ordering: false
        },
        {
          name: 'name',
          i18n: 'setting.access.table.header.name',
          width: '90%',
          filters: [],
          ordering: false
        }
      ],
      accessList: [],
      actions: [
        {
          /*toolbar: [
            {
              id: {name: 'insertAccessGroup'},
              type: 'link',
              i18n: 'setting.access.actions.insert',
              color: 'success',
              icon: '',
              iconPack: '',
            }
          ],*/
          // leftToolbar: [
          //   {
          //     id: {name: 'Settings'},
          //     type: 'link',
          //     icon: 'icon-chevron-left',
          //     iconPack: 'feather'
          //   }
          // ]
        }
      ]
    }
  },
  created () {
    // this.$vs.loading()
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)

    this.getRolesList()
  },
  methods: {
    consoleLog (log) {
      console.log(log)
    },
    getRolesList () {
      setTimeout(() => {
        // this.tree = {}
        getRoles().then(response => {
          this.tree = this.getRoles(response.data.data, true)
        })
      }, 500)
      /*setTimeout(() => {
        this.$vs.loading.close()
      }, 1500)*/
    },
    getRoles (role, first = false) {
      const data = {
        parent: {
          id: role.id,
          title: role.name,
          parent_id: role.parent_id,
          permissions: role.permissions
        },
        children: [],
        options: {
          insertEnable: true,
          updateEnable: !first,
          deleteEnable: role.children.length === 0,
          showEnable: true
        }
      }

      if (role.children && role.children.length > 0) {
        role.children.forEach((child) => {
          data.children.push(this.getRoles(child))
        })
      }

      return data
    }
  },
  watch: {
    '$store.state.helper.rolesChanged': {
      handler (val, oldVal) {
        this.getRolesList()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
